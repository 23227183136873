


/* 
ASL STYLES
*/

@import url("https://fonts.googleapis.com/css2?family=Fredericka+the+Great&display=swap");

.aslSlide {
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    align-items: center;
    text-align: center;
}

.aslSlide > div {
    z-index: 1;
    text-shadow: 2px 2px #0664b1;
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 7%;
}

.aslSlide > div > h1 {
    font-family: 'Fredericka the Great';
    color: white;
    
    /* text-shadow: 2px 2px #0664b1; */
    z-index: 1;
}

.aslSlide > div > h1 > span {
    color: #fad400;
}

.aslSlide > div > span {
    color: #ffffff;
    /* text-shadow: 2px 2px #0664b1; */
    z-index: 1;
}

@media screen and (min-height : 400px) {
    .aslSlide > div > h1 {
        font-size: 50px;
    }

    .aslSlide > div > span {
        font-size: 30px;
    }
}

@media screen and (max-height : 400px) {
    .aslSlide > div > h1 {
        font-size: 30px;
    }

    .aslSlide > div > span {
        font-size: 20px;
    }
}



.aslSlide > video {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
}

/* 
NBODY STYLES
*/
@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@700&display=swap");

.nbodySlide {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.nbodySlide > div {
    margin-top: 13%;
    margin-left: 10%;
    margin-right: 10%;
    z-index: 1;
    display: flex;
    flex-flow: column;
    align-items: center;
    text-align: center;
    margin-bottom: 5%;
}

.nbodySlide > div > h1 {
    font-family: 'Orbitron';
    color: white;
    font-size: 45px;
    text-shadow: 
        -1px -1px 0 #000,  
         1px -1px 0 #000,
        -1px  1px 0 #000,
         1px  1px 0 #000;
}

.nbodySlide > div > span {
    font-family: 'Orbitron';
    color: white;
    font-size: 30px;
    text-shadow: 
        -2px -2px 0 #000,  
         2px -2px 0 #000,
        -2px  2px 0 #000,
         2px  2px 0 #000;
}

.nbodySlide > video {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
}

/* 
SongBird STYLES
*/
@import url("https://fonts.googleapis.com/css2?family=Ultra&display=swap");

.songBirdSlide {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.songBirdSlide > h1 {
    font-family: 'Ultra', serif;
    color: white;
    text-shadow: 2px 2px #4a8b00;
}

.songBirdSlide > h1 > span {
    color: #5aa800;
}

.songBirdSlide > span {
    font-family: 'Ultra', serif;
    color: white;
    text-shadow: 2px 2px #4a8b00;
}

@media screen and (min-height : 400px) {
    .songBirdSlide  > h1 {
        font-size: 65px;
    }

    .songBirdSlide > div > span {
        font-size: 30px;
    }
}

@media screen and (max-height : 400px) {
    .songBirdSlide > h1 {
        font-size: 30px;
    }

    .songBirdSlide > span {
        font-size: 20px;
    }
}

.songBirdSlide > p {
    color: white;
    font-size: 34px;
    text-shadow: 2px 2px #4a8b00;
}

.birds {
    position: absolute;
    opacity: 0.6;
    top: 2%;
}