
.websiteCard {
    display: flex;
    width: 120px;
    height: 160px;
    flex-flow: column wrap;
    justify-content: center;
    text-align: center;
    margin-bottom: 10px;
    color: black;
}

.cardImage {
    display: block;
    width: 60px;
    height: 60px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
}