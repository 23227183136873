
/* 
BASE STYLES OF WEBSITE
*/

.body {
    height: 100%;
    margin: 0;
}

.root {
    height: 100%;
    display: flex;
    display:-webkit-flex;
    flex-flow: column;
}

.topNavBar {
    flex: 0 1 auto;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.85);
}

.mainBody {
    flex: 1 1 auto;
}

.footer {
    flex: 0 1 40px;
}

/*
General styles for all pages
*/

.pageHeader {
    font-size: 34px;
    text-align: center;
    margin-top: 45px;
    margin-bottom: 15px;
}

.websitesContainer {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
}

/* 
HOME PAGE STYLES
*/

.homeMainBody {
    display: flex;
    flex-direction: column;
    
}

.homeFooter {
    /* border-top: 1px solid rgb(184, 184, 184); */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #727272; 
    /* background-color: #7400f8; //7400f8 */
}

.homeFooterText {
    height: 100%;
    color: #ffffff;
    font-size: 18px;
    text-align: center;
}

@media screen and (min-width : 700px) {
    .homeFooterText {
        font-size: 18px;
    }
}

@media screen and (max-width : 700px) {
    .homeFooterText {
        font-size: 14px;
    }
}

.homePageLinkSection {
    height: 100%;
    background-color: #e9e9e9;
}

.bottomSeparator {
    fill: #727272;
    fill: #bee5ff;
    box-sizing: border-box;
    /* fill: rgb(17, 1, 1); */
}

@import url('https://fonts.googleapis.com/css2?family=Aldrich&display=swap');
.homePageText {
    position: relative;
    font-family: 'Aldrich', sans-serif;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;

    margin-top: 50px;
    margin-left:  15%;
    margin-right: 15%;
}

.homePageText > h1 {
    position: relative;
    margin-bottom: 15px;
}

.homePageText > span {
    position: relative;
}

@media screen and (min-width : 700px) {
    .homePageText > h1 {
        font-size: 50px;
    }

    .homePageText > span {
        font-size: 25px;
        margin-left:  5%;
        margin-right: 5%;
    }
}

@media screen and (max-width : 700px) {
    .homePageText > h1 {
        font-size: 25px;
    }
}

/* 
PUBLICATION PAGE STYLES
*/

.publicationContainer {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-bottom: 45px;
}

.publicationYearHeader{
    margin-bottom: 15px;
}

.publicationItem {
    width: 240px;
    margin-bottom: 10px;
}


/* 
CAROUSEL STYLES
*/

/* Style for carousel container*/
.carousel-container {
    position: absolute;

    flex: 1 1 auto;
    width:  100%;
    height: 100%;
    align-self: center;
    margin: auto;
    box-sizing: border-box;

    overflow: hidden;

    overflow-y: scroll;
    scrollbar-width: none;
    scroll-snap-type: y mandatory;
    
    /* border: 5px solid black; */
}

.carousel-container::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
}

.carousel-contents {
    position: relative;
    height: 100%;
    width: 100%;
    transition: transform ease-in-out 0.45s;
    display: flex;
    justify-content: left;
}

.vertical-carousel-contents {
    flex-direction: column;
    justify-content: left;
}

.carousel-slide {
    position: relative;
    scroll-snap-align: start;
    width: 100%;
    height: 100%;
    
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;

}

/*  Only defines the appearance of the arrow button, not its location
    or transform */
.carousel-arrow {
    position: fixed;
    
    background: #fff;
    opacity: .5;
    margin-top: -25px;
    margin-left: -25px;
    width: 50px;
    height: 50px;
    border: none;
    border-radius: 50%;

    margin-block: 20px;
    z-index: 2;
}

.carousel-arrow:hover {
    transform: scale(1.1);
    transition: transform opacity ease-in-out 3s;
    opacity: .8;
}

.carousel-arrow-style {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

/*  */
.carousel-index {
    position: fixed;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    top: 50%;
    right: 0px;
    transform: translate(-50%, -50%);
}

.carousel-index-line {
    height: 34px;
    border-left: 1px solid rgb(255, 255, 255);
    margin: auto;
}

.carousel-index-donut {
    position: relative;

    background-color: Transparent;
    
    margin-top: 4px;
    margin-bottom: 4px;
    
    padding:0;
    display: block;
    box-sizing: border-box;

    border-radius: 50%/50%;
    border: solid 2px rgb(255, 255, 255);
    width:  20px;
    height: 20px;
}

.carousel-current-index-donut:hover {
    cursor: default;
}

.carousel-current-index-donut-mark {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width:  14px;
    height: 14px;
    background-color: rgb(255, 255, 255);
    transition: opacity ease-in-out .75s;
    border-radius: 50%;

    padding:0;
    display: block;
    box-sizing: border-box;
}